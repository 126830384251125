<template>
    <div class="section">
    <div class="sec-adoptatree">
        <div class="adoptatree-title">La Finca Trees</div>
        <v-row class="mt-5">
            <v-col cols="12" md="3">
                <router-link to="tree-profile">
                <v-img
                    alt="La Finca Tree"
                    class="shrink tree-img"
                    contain
                    src="../assets/trees/santol.jpg"
                    transition="scale-transition"
                    width="200"
                    height="200"
                />
                </router-link>
                <p class="fruit-label">Cotton Fruit</p>
            </v-col>
            <v-col cols="12" md="3">
                <router-link to="tree-profile">
                <v-img
                    alt="La Finca Tree"
                    class="shrink tree-img"
                    contain
                    src="../assets/trees/duhat.jpg"
                    transition="scale-transition"
                    width="200"
                    height="200"
                />
                </router-link>
                <p class="fruit-label">Java Plum</p>
            </v-col>
            <v-col cols="12" md="3">
                <router-link to="tree-profile">
                <v-img
                    alt="La Finca Tree"
                    class="shrink tree-img"
                    contain
                    src="../assets/trees/bugnay.jpg"
                    transition="scale-transition"
                    width="200"
                    height="200"
                />
                </router-link>
                <p class="fruit-label">Wild Cherry</p>
            </v-col>
            <v-col cols="12" md="3">
                <router-link to="tree-profile">
                    <v-img
                        alt="La Finca Tree"
                        class="shrink tree-img"
                        contain
                        src="../assets/trees/balimbing.jpg"
                        transition="scale-transition"
                        width="200"
                        height="200"
                    />
                </router-link>
                <p class="fruit-label">Star Fruit</p>
            </v-col>
        </v-row>
    </div>


  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .section{padding: 5px 65px 5px 65px;}
    .sec-adoptatree{
        margin-top: 20px;
        text-align: justify;
    }
    .adoptatree-title{
        font-size: 25px;
        font-weight: bold;
    }
    .sec-btns{
        margin-top: 50px;
    }
    .tree-img{
        margin: 0 auto;
    }
    .fruit-label{
        text-align: center;
        font-weight: bold;
    }
</style>